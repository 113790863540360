import { Component, OnInit, OnDestroy } from "@angular/core";


@Component({
  selector: "app-index",
  templateUrl: "index.component.html"
})
export class IndexComponent implements OnInit {

  constructor() {
  }
  ngOnInit() {}
}
