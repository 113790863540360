import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PaymentStatusService} from '../../service/payment-status.service';
import {Router} from "@angular/router";
// @ts-ignore-blank-lines
@Component({
  selector: 'app-payment-page-content',
  templateUrl: './payment-page-content.component.html',
  styleUrls: ['./payment-page-content.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PaymentPageContentComponent implements OnInit {

  paymentStatus = -1; // -1 solicitando datos, 1 success, 0 pending, 2 failure
  serviceEnterprise = ''; // Vendrá de la consulta
  imgUrl = 'assets/img/ICH_LOG3ico.png';// Vendrá de la consulta
  returnUrl = '#';// Vendrá de la consulta
  // [serviceEnterprise]="'Cloudpsy'" [imgUrl]="'assets/img/ICH_LOG3ico.png'

  serviceItem = '3 meses';
  serviceTitle ='Plan de suscripción';
  price= '$9000';
  clientName='Bartolo';
  clientMail='bartolo@bar.com';
  vendorName ='Roberto lecae';
  expiration ='25/01/2021 07:45';
  constructor(private paymentService:PaymentStatusService,private router: Router) {

    const urlTree = this.router.parseUrl(this.router.url);
    const hash  = urlTree.queryParams.hash; // ['hash'];

    this.paymentService.getStatus(hash).subscribe((datos:any)=>{

      // @ts-ignore all
      this.paymentStatus = datos.status; // 0 success, 1 pending, 2 failure
      this.serviceEnterprise = datos.service; // Vendrá de la consulta
      this.imgUrl       = datos.logoUrl;// Vendrá de la consulta
      this.returnUrl    = datos.returnUrl;// Vendrá de la consulta
      this.serviceItem  = datos.serviceItem;
      this.serviceTitle = datos.serviceTitle;
      this.price        = datos.price;
      this.clientName   = datos.clientName;
      this.clientMail   = datos.clientMail;
      this.vendorName   = datos.vendorName;
      this.expiration   = datos.expiration;
    })
  }

  ngOnInit(): void {
  }

}
