import { Component, OnInit } from '@angular/core';
import {MailingService} from '../../service/mailing.service';
import {MaillingElement} from '../../common/interface/MaillingElement';
import {ValidatorService} from '../../service/validator.service';
import {ActivatedRoute} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  focus;
  focus1;
  focus2;
  focus3;

  message: MaillingElement ;

  name = '';
  mail = '';
  phone = '';
  messageContent = '';
  item = 'other';

  constructor(private mailingService: MailingService,
              private validatorService :ValidatorService,
              private route: ActivatedRoute) {


  }

  ngOnInit() {
    // this.route.paramMap.subscribe(params => {
    //   this.animal = params.get("animal")
    // })
    this.route.paramMap.subscribe(params => {
      this.item = params.get('item');
      if(this.item){
        const nameTransformed = this.item.replace('_', ' ');
        this.messageContent = this.item !== '' && this.item != null ? 'Quiero una '+nameTransformed+': ' : '' ;
        (document.getElementById(this.item) as HTMLOptionElement).selected  = true ;
      }

    });
  }



  saveNewMessage(){

    if(!this.validatorService.isAValidEmailAddress(this.mail)){

      alert('Formato Email Incorrecto');

    } else {

      this.message={
        name: this.name,
        mail: this.mail,
        phone: this.phone,
        message: this.messageContent, 
        urlSource: window.location.href
      } ;


      this.mailingService.save(this.message).subscribe((result:any) => {
        console.log('Mensaje obtenido');
        console.log(result);
        alert(result.message);
        this.showNotification('Artículo (' + result.message + ') Creado', 1, 2);
      });
      console.log(this.name+ ' '+this.mail + ' '+ this.messageContent);
      console.log(JSON.stringify(this.message));
    }
  }



  showNotification(message, color, icon) {
    const colorArr = ['info', 'success', 'warning', 'danger'];
    const iconArr = ['pe-7s-trash', 'pe-7s-close-circle', 'pe-7s-check'];
    $.notify({
      icon: iconArr[icon],
      message: message
    }, {
      type: colorArr[color],
      timer: 1000,
      placement: {
        from: 'top',
        align: 'center'
      }
    });
  }
}
// 'one_page'
// 'multi_page'
// 'web_app'
// 'update'
// 'other'
