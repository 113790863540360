import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {

  path = '';

  constructor(private router: Router) { 
    console.log(this.router.url);
    this.path = this.router.url;
  }

  ngOnInit(): void {
  }

}
