import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-img-and-description-section',
  templateUrl: './img-and-description-section.component.html',
  styleUrls: ['./img-and-description-section.component.scss']
})

/*
 Renderiza un titulo arriba y debajo una imagen a compañada de un texto
 Si le envia la variable left = false, pondra la imagen a la derecha de lo contrario
 siempre lo hara a la izquierda (El parametro es opcional)
*/
// TODO las entradas de texto deben poder recibir etiquetas html
export class ImgAndDescriptionSectionComponent implements OnInit {

  @Input() imageUrl: string;
  @Input() title: string;
  @Input() descriptionTitle: string;
  @Input() descriptionText: string[];
  @Input() left  = true;
  descriptionTextFormated : string[] =[];

  constructor()  {}

  ngOnInit(): void {
    this.descriptionTextFormated = this.descriptionText;
    /*for( let desc of this.descriptionText){
      if(desc.includes('&&%'))
        desc = this.makeMeHtml(desc);
       this.descriptionTextFormated.push( desc );
    }*/
  }

  private makeMeHtml(desc: string) {
    let n = desc.replace('&&%','<em>');
    n = n.replace('&&%','</em>');
    return n;
  }
}
