import { Component, OnInit,ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-admin-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})

// 'encapsulation: ViewEncapsulation.None,' es usado para quitar la encapsulacion (podemos acceder al body por ejemplo)
export class AdminLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
