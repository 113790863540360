import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item-price',
  templateUrl: './item-price.component.html',
  styleUrls: ['./item-price.component.scss']
})
export class ItemPriceComponent implements OnInit {

  @Input() imgsrc = 'assets/img/undraw_collecting_fjjl.png';

  @Input() descriptions: string;
      desc : string[] = [];
  @Input() name = 'Landing Page';
  @Input() price = 2000;


  constructor() {
  }

  ngOnInit(): void {
    this.showDescription();
  }

  showDescription():void{
    let temp = '';
    let j = 0;
    for(const c of this.descriptions){
      if(c === ';'){
        this.desc[j] = temp;
        temp ='';
        j++;
      }
      else
        temp += c;
    }
    this.desc[j] =temp;
  }
}
