import { Component, OnInit } from '@angular/core';
import {ImgAndDescriptionSectionComponent} from '../../components/img-and-description-section/img-and-description-section.component';
import { ResourceService } from 'src/app/service/resource.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  onePageTitle = 'One page';
  onePageImgUrl = 'assets/img/monitorWeb.png';
  onePageDescriptionTitle = 'Descripción';
  onePageDescriptionText:string[] = ['Una ONE PAGE  es una web que contiene todo el contenido en una sola pagina.',
    'Pueden ser usadas como Landing page, como punto de aterrizaje para detallar algún producto o servicio, o como presencia web personal o para una empresa, mostrando una mayor seriedad de esta.'];


  sectionOnes : ImgAndDescriptionSectionComponent[] = [];

  proyectList:any[] = [];

  constructor(private resourceService: ResourceService)  {
    const sectionOne : ImgAndDescriptionSectionComponent = new ImgAndDescriptionSectionComponent();
    sectionOne.title ='Startup';
    sectionOne.descriptionTitle = '> Crea tu negocio online ';
    sectionOne.descriptionText = ['Nosotros te construimos la solución que necesitas, con todas las funcionalidades que necesites.'];
    sectionOne.imageUrl = 'assets/img/web_app1.png';

    this.sectionOnes.push(sectionOne);

    let sectionOne2 : ImgAndDescriptionSectionComponent = new ImgAndDescriptionSectionComponent();

    sectionOne2 = new ImgAndDescriptionSectionComponent();
    sectionOne2.title    = 'Servicio de actualización';
    sectionOne2.descriptionTitle = '> Actualiza tu negocio ';
    sectionOne2.descriptionText  = ['Actualiza tu sistema dandole nuevas funcionalidades y facilidades a tus clientes.'];
    sectionOne2.imageUrl = 'assets/img/updatePageService.png';
    sectionOne2.left = false;

    this.sectionOnes.push(sectionOne2);

    sectionOne2 = new ImgAndDescriptionSectionComponent();
    sectionOne2.title    = 'Presencia online';
    sectionOne2.descriptionTitle = '> Obten tu presencia web ';
    sectionOne2.descriptionText  = [' Aumenta tus ventas, tu confiabilidad ante los cliente y seriedad de tu producto o' +
    ' servicio con una web compacta y moderna.'];
    sectionOne2.imageUrl = 'assets/img/businessWebExamples.png';

    this.sectionOnes.push(sectionOne2);

    this.loadProyects(); 
  }

  ngOnInit(): void {
    window.scroll(0, 1);// <--- esto arregla el error de hacer doble clicl o scrol para q se carguen los datos
  }
  


  loadProyects(){
    const listName = {"name": "ich-home"};
    //const listName = {"name": "ich-home_en"};
    this.resourceService.getAllProyects(listName).subscribe((result:any) => {
      console.log(result);

      this.proyectList = result;
      console.log(this.proyectList);

    });

  }

}
