import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}

  public transform(values: any[], type: string): SafeHtml[] | SafeStyle[] | SafeScript[] | SafeUrl[] | SafeResourceUrl[] {

    const result:any[] = [] ;

    for(const value of values){
      switch (type) {
        case 'html':
          result.push( this.sanitizer.bypassSecurityTrustHtml(value) );
          break;
        case 'style':
          result.push( this.sanitizer.bypassSecurityTrustStyle(value));
          break;
        case 'script':
          result.push( this.sanitizer.bypassSecurityTrustScript(value));
          break;
        case 'url':
          result.push( this.sanitizer.bypassSecurityTrustUrl(value));
          break;
        case 'resourceUrl':
          result.push( this.sanitizer.bypassSecurityTrustResourceUrl(value));
          break;
        default:
          throw new Error(`Invalid safe type specified: ${type}`);
      }
    }

    return result;
  }
}
