import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { MenuComponent } from '../components/menu/menu.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { MainFooterComponent } from '../components/main-footer/main-footer.component';
import { ItemPriceComponent } from '../components/item-price/item-price.component';
import {ImgAndDescriptionSectionComponent} from '../components/img-and-description-section/img-and-description-section.component';
import {SafePipe} from '../pipes/safe-pipe.pipe';
import {SliderComponent} from '../components/slider/slider.component';
import {ServiceItemComponent} from '../components/service-item/service-item.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import {ShowMessageComponent} from '../components/show-message/show-message.component';
import {EditRowComponent} from '../components/edit-row/edit-row.component';
import {BannerComponent} from '../components/banner/banner.component';
import {WhatsappButtonComponent} from '../components/whatsapp-button/whatsapp-button.component';
import {PaymentPageContentComponent} from '../components/payment-page-content/payment-page-content.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        BsDropdownModule.forRoot(),
        ProgressbarModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        CollapseModule.forRoot(),
        JwBootstrapSwitchNg2Module,
        TabsModule.forRoot(),
        PaginationModule.forRoot(),
        AlertModule.forRoot(),
        BsDatepickerModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot()
    ],
    declarations: [
        IndexComponent,
        AboutComponent,
        MenuComponent,
        ProductsComponent,
        ServicesComponent,
        ContactComponent,
        HomeComponent,
        MainFooterComponent,
        ItemPriceComponent,
        ImgAndDescriptionSectionComponent,
        SafePipe,
        SliderComponent,
        ServiceItemComponent,
        AdminLoginComponent,
        ShowMessageComponent,
        EditRowComponent,
        BannerComponent,
        WhatsappButtonComponent,
        PaymentPageContentComponent,
        PaymentStatusComponent,
    ],
  exports: [
    IndexComponent,
  ],
  providers: []
})
export class PagesModule {}
