import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss']
})
/*
* Para crear una card de productos con titulo, precio y lista de items.
* La lista de items vienen dadas por un string que separa cada item por un ';'
* */
export class ServiceItemComponent implements OnInit {

  @Input() desc: string;
  descriptions: string[];
  @Input() titleItem = 'Producto';
  @Input() price = '2000';


  constructor() {
  }


  ngOnInit(): void {
    // console.log(this.desc);
    this.descriptions = this.desc.split(';');
  }

  transformName(){
    const nameTransformed = this.titleItem.replace(' ', '_');
    // console.log(nameTransformed.toLowerCase());
    return nameTransformed.toLowerCase();
  }
}
