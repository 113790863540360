import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Item} from '../../common/interface/Item';



@Component({
  selector: 'app-edit-row',
  templateUrl: './edit-row.component.html',
  styleUrls: ['./edit-row.component.scss']
})
export class EditRowComponent implements OnInit {

  @Input() title = 'Item';
  @Input() item: Item ;

  @Output() itemEdited = new EventEmitter<Item>();


  constructor() { }

  ngOnInit(): void {
  }

  // Evento de guardado de item
  saveItem(){
    this.itemEdited.emit(this.item);

  }
}
