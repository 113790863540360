import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './pages/index/index.component';
import { AboutComponent }  from './pages/about/about.component';
import { ContactComponent }  from './pages/contact/contact.component';
import { HomeComponent }  from './pages/home/home.component';
import { ProductsComponent }  from './pages/products/products.component';
import { ServicesComponent }  from './pages/services/services.component';
import {AdminLoginComponent} from './pages/admin-login/admin-login.component';
import {PaymentStatusComponent} from './pages/payment-status/payment-status.component';

const routes: Routes = [
  /* { path: '', redirectTo: 'home', pathMatch: 'full' }, */
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'index', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'contact/:item', component: ContactComponent },
  { path: 'products', component: ProductsComponent },
 // { path: 'home2', component: HomeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'login', component: AdminLoginComponent },
  { path: 'payment/status', component: PaymentStatusComponent}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: []
})
export class AppRoutingModule {}
