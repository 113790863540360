import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {MaillingElement} from '../common/interface/MaillingElement';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailingService {

  url='http://localhost/ich/server/mailingNewStructure/'; // disponer url de su servidor que tiene las páginas PHP

  constructor(private http: HttpClient) { }


  // CRUD PARA MAILING (todos hacen uso del un objeto como entrada)
  delete(idMailObj) {
    return this.http.post(`${this.url}delete.php`, JSON.stringify(idMailObj));
  }

  get(idMailObj) {
    return this.http.post(`${this.url}get.php`, JSON.stringify(idMailObj));
  }

  // Tiene otro tipo de objeto (con las siguientes claves "initDate""endDate")
  getAll(optionsToGetMails): Observable<MaillingElement[]> {
    const data  =  optionsToGetMails ? JSON.stringify(optionsToGetMails) :null;
    return this.http.post<MaillingElement[]>(`${this.url}getAll.php`, data);
  }

  modify(modifMail) {
    return this.http.post(`${this.url}modify.php`, JSON.stringify(modifMail));
  }

  save(newMail) {
    return this.http.post(`https://ideaschacon.com/ich/servicios/messages/send.php`, JSON.stringify(newMail));
  }

/*  // Metodo para metodo GET (y no post como los anteriores)
  baja(codigo:number) {
    return this.http.get(`${this.url}baja.php?codigo=${codigo}`);
  }*/
}
