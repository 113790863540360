import { Injectable }    from '@angular/core';

@Injectable({
      providedIn: 'root'
})
export class ValidatorService {

  isAValidEmailAddress(email: string):boolean {
    let mailValido = false;

    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(EMAIL_REGEX)){
      mailValido = true;
    }
    return mailValido;
  }
}
