/* tslint:disable:prefer-const */
import { Component, OnInit } from '@angular/core';
import {ServiceItemComponent} from '../../components/service-item/service-item.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  serviceList : ServiceItemComponent[] = [];

  constructor() {

  }

  ngOnInit(): void {
    const commonfetures = ' Formulario de contacto;Enlaces con Redes Sociales; Alta en buscadores;' +
        ' Botón flotante de WhatsApp; Certificado de Sitio Seguro (SSL);Hosting Gratis por un año;';

    let serviceItem = new ServiceItemComponent();
    serviceItem.titleItem = 'One Page';
    serviceItem.price = '25 USD';
    serviceItem.desc = '1 única página;Adaptable a todos los dispositivos;' + commonfetures ;
    this.serviceList.push(serviceItem);

    serviceItem = new ServiceItemComponent();
    serviceItem.titleItem = 'Multi Page';
    serviceItem.price = '35 USD';
    serviceItem.desc = '5/6 cuerpos;Adaptable a todos los dispositivos;Autoadministrable;' +
        'Seccion de control de contenidos;' + commonfetures ;
    this.serviceList.push(serviceItem);

    serviceItem = new ServiceItemComponent();
    serviceItem.titleItem = 'E-commerce';
    serviceItem.price = '50 USD (desde)';
    serviceItem.desc = 'cuerpos necesarios;Adaptable a todos los dispositivos;Autoadministrable;' +
        ' Pasarela de pago (Mercado Pagos/paypal/ otros);' + commonfetures ;
    this.serviceList.push(serviceItem);

    serviceItem = new ServiceItemComponent();
    serviceItem.titleItem = 'Web app';
    serviceItem.price = '70 USD (desde)';
    serviceItem.desc = 'cuerpos necesarios;Adaptable a todos los dispositivos;Autoadministrable;' +
        commonfetures ;
    this.serviceList.push(serviceItem);

    /*    serviceItem = new ServiceItemComponent();
        serviceItem.titleItem = 'Actualización';
        serviceItem.price = '(Según la funcionalidad)';
        serviceItem.desc = ';Adaptable a todos los dispositivos;Autoadministrable;' +
            ' Pasarela de pago (Mercado Pagos/paypal/ otros);' + commonfetures ;
        this.serviceList.push(serviceItem);*/
    // console.log(this.serviceList);

    window.scroll(0, 1);// <--- esto arregla el error de hacer doble clicl o scrol para q se carguen los datos
  }

}
