import {Component, Input, OnInit} from '@angular/core';
import {MaillingElement} from '../../common/interface/MaillingElement';

export interface KeyMap {
  name: string;
  value:string;
}

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.scss']
})
export class ShowMessageComponent implements OnInit {

  @Input() title:string;

  @Input() fullMessage: MaillingElement;

  constructor() {
      this.title = 'Mensaje';
  }

  ngOnInit(): void {}


    // Funciones para recoger eventos
    response(){}

    deleteMsg(){}
}
