import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  url='';
  constructor(private http: HttpClient) { }

  getAllProyects(optionsToGet) {
    const data  =  optionsToGet ? JSON.stringify(optionsToGet) :null;
    return this.http.post('https://ideaschacon.com/ich/servicios/links/getLinksBySection.php', data);
  }
}
