@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  onePageTitle = 'One page';
  onePageImgUrl = 'assets/img/monitorWeb.png';
  onePageDescriptionTitle = 'Descripción';
  onePageDescriptionText:string[] = ['Una ONE PAGE  es una web que contiene todo el contenido en una sola pagina.',
    'Pueden ser usadas como Landing page, como punto de aterrizaje para detallar algún producto o servicio, o como presencia web personal o para una empresa, mostrando una mayor seriedad de esta.'];
  /*    'Una <em style=\"color:#ffffff;font-style: italic;margin-right: 1px;\">ONE PAGE</em>  es una web que contiene
  t odo el contenido en una sola pagina.',*/

  listSections: PageContent[] = [];

  constructor() {

     let page1 :PageContent ={
      title:'Web de negocios',
       imgUrl : 'assets/img/businessWebExamples.png',
      descriptionTitle:'Descripción',
      descriptionText: ['Una ONE PAGE  es una web que contiene todo el contenido en una sola pagina.',
        'Pueden ser usadas como Landing page, como punto de aterrizaje para detallar algún producto o servicio, o como presencia web personal o para una empresa, mostrando una mayor seriedad de esta.']
    };
    this.listSections.push(page1);

    page1  ={
      title:'Web APP',
      imgUrl : 'assets/img/web_app1.png',
      descriptionTitle:'Descripción',
      descriptionText: ['Una Web APP es una web que permite la interación con el usuario, la cual esta preparada para que se puede acceder tanto desde un PC como desde un dispositivo movil.',
        'Ej: Facebook, Amazon, Youtube, etc.']
    };
    this.listSections.push(page1);

    page1 ={
      title:'Actualiza tu pagina',
      imgUrl : 'assets/img/updatePageService.png',
      descriptionTitle:'Descripción',
      descriptionText: ['Una ONE PAGE  es una web que contiene todo el contenido en una sola pagina.',
        'Pueden ser usadas como Landing page, como punto de aterrizaje para detallar algún producto o servicio, o como presencia web personal o para una empresa, mostrando una mayor seriedad de esta.']
    };
    this.listSections.push(page1);

    page1 ={
      title:'Multi Pages',
      imgUrl : 'assets/img/multiPage1.png',
      descriptionTitle:'Descripción',
      descriptionText: ['Una ONE PAGE  es una web que contiene todo el contenido en una sola pagina.',
        'Pueden ser usadas como Landing page, como punto de aterrizaje para detallar algún producto o servicio, o como presencia web personal o para una empresa, mostrando una mayor seriedad de esta.']
    };
    this.listSections.push(page1);

    page1  ={
      title:'One Page',
      imgUrl : 'assets/img/onePage1.png',
      descriptionTitle:'Descripción',
      descriptionText: ['Una ONE PAGE  es una web que contiene todo el contenido en una sola pagina.',
        'Pueden ser usadas como Landing page, como punto de aterrizaje para detallar algún producto o servicio, o como presencia web personal o para una empresa, mostrando una mayor seriedad de esta.']
    };
    this.listSections.push(page1);

    page1 ={
      title:'Web de negocios',
      imgUrl : 'assets/img/webExamples2.png',
      descriptionTitle:'Descripción',
      descriptionText: ['Una ONE PAGE  es una web que contiene todo el contenido en una sola pagina.',
        'Pueden ser usadas como Landing page, como punto de aterrizaje para detallar algún producto o servicio, o como presencia web personal o para una empresa, mostrando una mayor seriedad de esta.']
    };
    this.listSections.push(page1);

  }

  ngOnInit(): void {




  }


}

import { Component, OnInit } from '@angular/core';

interface PageContent{
  title,
  imgUrl,
  descriptionTitle,
  descriptionText,
}
