import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {ImgAndDescriptionSectionComponent} from '../img-and-description-section/img-and-description-section.component';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
/*
   Renderiza un carrusel de componentes
*/
// TODO limpiar la funcion del carruselshowRight
export class SliderComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input ('imgs') sld: string;
  // tslint:disable-next-line:no-input-rename
  @Input('timer') transition: string;
  imgs;
  imag: any[] = [];
  sizeul : number;
  sizeli: number;
  animation: string;
  time:number ;
  sliderc: string;
  sliderl; string;

 @Input() sectionOnes :  ImgAndDescriptionSectionComponent[]  = [];
  sections : ImgAndDescriptionSectionComponent[] = [];

  onePageTitle = 'One page';
  onePageImgUrl = 'assets/img/monitorWeb.png';
  onePageDescriptionTitle = 'Descripción';
  onePageDescriptionText:string[] = ['Una ONE PAGE  es una web que contiene todo el contenido en una sola pagina.',
    'Pueden ser usadas como Landing page, como punto de aterrizaje para detallar algún producto o servicio.',
    'O como presencia web personal o para una empresa, mostrando una mayor seriedad de esta.'];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.imgs = this.sld.split(',');
    this.sizeul = 100 * this.imgs.length;
    this.sizeli = 100 / this.imgs.length;
    // tslint:disable-next-line:radix
    this.time = parseInt(this.transition) * this.imgs.length;

    // tslint:disable-next-line:prefer-const
    let sct: string;
    let lt: string;
    let porcentaje = 0;
    lt = '@keyframes carouselM { '
    for( let x = 0 ; x <= 100; x = x + this.sizeli){
      // tslint:disable-next-line:triple-equals
      if(porcentaje == this.sizeul || porcentaje == 0){
        // tslint:disable-next-line:triple-equals
        if(porcentaje != 100){
          lt += x - 5 +'% {left: -'+(porcentaje-100)+'%;} ';
        }
        porcentaje = 0;
        lt += x +'% {left: '+porcentaje+'%;} ';
        porcentaje += 100;
      }else{
        lt += x - 5 +'% {left: -'+(porcentaje-100)+'%;} ';
        lt += x +'% {left: -'+porcentaje+'%;} ';
        porcentaje += 100;
      }
    }
    lt += ' };';
    // create style
    const hd = document.getElementsByTagName('head')[0];
    const cr = document.createElement('style');
    const tx = document.createTextNode(lt);
    cr.appendChild(tx);
    hd.appendChild(cr);

    this.sliderc = this.sizeul.toString() + '%';
    this.animation = 'carouselM '+ this.time+'s infinite';
    this.sliderl = this.sizeli.toString() + '%';

    this.sliderl = this.sizeli.toString() + '%';
    /*this.imgs.forEach(e => {
      this.imag.push(this.sanitizer.bypassSecurityTrustStyle('url(./assets/img/src_assets_slider_' + e + '.jpg)'))
    });*/
    // for(const e of this.sectionOnes){
    //   this.sections.push(e);
    // }

//    console.log('Cardinal de sectionOnes:'+this.sectionOnes.length);
  //  console.log('Cardinal de sections:'+this.sections.length);
  }
}
